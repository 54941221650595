// Angular
import {
    Component,
    OnInit,
    ViewEncapsulation,
    HostListener,
} from '@angular/core';

// App
import { LandaService } from 'src/app/core/services/landa.service';

@Component({
    selector: 'app-mobile',
    templateUrl: './mobile.component.html',
    styleUrls: ['./mobile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MobileComponent implements OnInit {
    constructor(private landaService: LandaService) {}

    ngOnInit(): void {
        document.body.setAttribute('data-layout', 'mobile');
    }

    // @HostListener("window:scroll", [])
    // onWindowScroll() {
    //     if (
    //         document.body.scrollTop > 120 ||
    //         document.documentElement.scrollTop > 120
    //     ) {
    //         document.getElementById("mobile-top-nav").classList.add("navbar-mobile-scroll");
    //         document.getElementById("mobile-top-nav").classList.remove("scroll-fade");
    //     } else {
    //         document.getElementById("mobile-top-nav").classList.remove("navbar-mobile-scroll");
    //         document.getElementById("mobile-top-nav").classList.add("scroll-fade");
    //     }
    // }
}
