<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">{{ year }} © PT. Binakarya Nasional Terbuka</div>
      <!-- <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          Developed by
          <a
            href="https://venturo.id/"
            class="text-decoration-none text-reset"
            target="_blank"
            >Landa Systems</a>
        </div>
      </div> -->
    </div>
  </div>
</footer>
