<div id="layout-wrapper">
    <app-horizontaltopbar></app-horizontaltopbar>
    <div class="main-content">
        <div class="page-content" id="page-content">
            <router-outlet></router-outlet>
        </div>
    </div>

    <app-footer></app-footer>
    <app-rightsidebar></app-rightsidebar>
</div>
