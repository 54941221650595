import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { LandaService } from '../services/landa.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private landaService: LandaService
    ) {}

    has(obj, key) {
        return obj ? Object.prototype.hasOwnProperty.call(obj, key) : false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // this.landaService.DataGet("/site/ip", {}).subscribe((res: any) => {
        //   if (res.data !== null) {
        //     if (res.data.status === false) {
        //       this.router.navigate(["/account/block"]);
        //       return false;
        //     }
        //   }
        // });
        // this.landaService.DataGet("/site/session", {}).subscribe((res: any) => {
        //   if (res.status_code === 422) {
        //     return true;
        //   } else {
        //     this.router.navigate(["/backoffice/home"]);
        //     return false;
        //   }
        // });
        return true;
    }
}
