<header id="page-topbar" style="z-index: 1450 !important;">
    <div class="navbar-header">
        <div class="d-flex">
            <div class="d-inline-block d-none d-lg-block d-lg-block" style="padding-top: 17px"></div>
            <!-- <div class="top-nav d-inline-block" *ngIf="checkAkses('notifikasi', 'status') && !acc_resign">
                <button type="button" (click)="onMenuClick($event)" class="btn header-item noti-icon right-bar-toggle"
                    id="page-header-notifications-dropdown" [routerLink]="['chat']">
                    <i class="bx bx-chat"></i>
                </button>
            </div> -->
            <!-- <div class="nav-item d-block mb-2">
                <h1 class="font-size-14" *ngIf="['/backoffice/home']">Pengguna</h1>
            </div> -->
            <div class="top-nav d-inline-block" *ngIf="checkAkses('notifikasi', 'status') && !acc_resign">
                <button type="button" (click)="onMenuClick($event)" class="btn header-item noti-icon right-bar-toggle" id="page-header-notifications-dropdown" [routerLink]="['pemberitahuan/sidelist']">
                    <i class="bx bx-bell"></i>
                    <span _ngcontent-qub-c136="" class="badge bg-danger rounded-pill" style="padding-top: 3px">
                        {{ totalData }}
                    </span>
                </button>
            </div>
            <div class="top-nav d-inline-block" *ngIf="checkAkses('pengaturan', 'status') && !acc_resign">
                <button type="button" (click)="onMenuClick($event)" [routerLink]="['setting/global']"
                    class="btn header-item noti-icon right-bar-toggle" id="page-header-notifications-dropdown">
                    <i class="bx bx-cog"></i>
                </button>
            </div>
            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                    <ng-template #tipUser>
                        <div style="
                                min-width: 100px;
                                max-width: 200px;
                                text-align: left;
                            "></div>
                    </ng-template>
                    <img class="rounded-circle header-profile-user" src="assets/images/users/user-default.png"
                        placement="bottom" />
                    <span class="d-none d-lg-inline-block ml-1">{{ user.nama_lengkap }} &nbsp;<i
                            class="fas fa-angle-down"></i>
                    </span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <div class="dropdown-divider d-lg-none"></div>
                    <a class="dropdown-item d-block" *ngIf="!acc_resign" href="javascript: void(0);"
                        (click)="onSubMenuClick($event)" [routerLink]="['pengguna/profile']">
                        <i class="fas fa-user font-size-12 align-middle mr-1"></i> {{ 'Profil' | translate }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="modalBahasa(bahasa)">
                        <i class="fas fa-language font-size-12 align-middle mr-1"></i> {{ 'Bahasa' | translate }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="checkupdate()">
                        <i class="fas fa-sync font-size-12 align-middle mr-1"></i> {{ 'Perbarui Sistem' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="topnav" style="z-index: 1599 !important" id="topnav">
    <nav class="navbar navbar-expand-lg topnav-menu active">
        <div class="active" id="topnav-menu-content" style="height: 100%; width: 100%;">
            <ul class="navbar-nav" *ngIf="!acc_resign">
                <!-- LOGO -->
                <div class="nav-item d-block mb-2">
                    <a class="logo logo-dark" *ngIf="!acc_resign">
                        <span class="logo-sm"></span>
                        <span class="logo-lg">
                            <img alt="250x250" [src]="logo_aplikasi.ikon_sidebar" class="text-center" class="logo-favicon" height="20" />
                            <img alt="250x250" [src]="logo_aplikasi.logo_sidebar" class="text-center" class="logo-img" height="30" />
                        </span>
                    </a>
                    <div *ngIf="acc_resign">
                        <span class="logo-sm"></span>
                        <span class="logo-lg">
                            <img alt="250x250" [src]="logo_aplikasi.ikon_sidebar" class="text-center" class="logo-favicon" height="20" />
                            <img alt="250x250" [src]="logo_aplikasi.logo_sidebar" class="text-center" class="logo-img" height="30" />
                        </span>
                    </div>
                </div>
                <!-- <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
                    <i class="fa fa-fw fa-bars font-size-16"></i>
                </button> -->
                <li class="nav-item">
                    <a class="nav-link arrow-none text-nowrap text-white d-flex active" (click)="onMenuClick($event)"
                        [routerLink]="['/backoffice/home']" href="javascript: void(0);" id="topnav-components"
                        role="button" gaEvent="click_test" gaCategory="ga_directive_test">
                        <img src="assets/images/nav_home.png" height="18" class="mr-3">
                        <span class="text-link">
                            {{ 'Beranda' | translate }}
                        </span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link arrow-none text-nowrap text-white d-flex" (click)="onMenuClick($event)"
                        [routerLink]="['/backoffice/pengguna/daftar']" href="javascript: void(0);"
                        id="topnav-components" role="button" *ngIf="checkAkses('pengguna', 'status')"
                        gaEvent="click_test" gaCategory="ga_directive_test">
                        <img src="assets/images/nav_user.png" height="18" class="mr-3">
                        <span class="text-link">
                            {{ 'Pengguna' | translate }}
                        </span> 
                    </a>
                </li>
                <div class="dropdown" ngbDropdown *ngIf=" checkAkses('produk', 'status')">
                    <li class="dropdown" ngbDropdowntoggle>
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <i class='bx bx-store-alt mr-3'></i>
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="text-link">{{ 'Toko' | translate }}</span> 
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown">
                                <!-- *ngIf="checkAkses('produk_kategori','status')" -->
                                <a class="dropdown-item dropdown-toggle arrow-none" 
                                    (click)="onSubMenuClick($event)" 
                                    href="javascript: void(0);"
                                    [routerLink]="['/backoffice/toko/seller']"
                                    role="button"
                                    gaEvent="click_test"
                                    gaCategory="ga_directive_test">
                                    {{ 'Seller' | translate }}
                                </a>
                                <a class="dropdown-item dropdown-toggle arrow-none" 
                                    (click)="onSubMenuClick($event)" 
                                    href="javascript: void(0);"
                                    [routerLink]="['/backoffice/toko/verifikasi']"
                                    role="button"
                                    gaEvent="click_test"
                                    gaCategory="ga_directive_test">
                                    {{ 'Pengajuan Seller' | translate }}
                                </a>
                                <a class="dropdown-item dropdown-toggle arrow-none" 
                                    (click)="onSubMenuClick($event)" 
                                    href="javascript: void(0);"
                                    [routerLink]="['/backoffice/toko/kategori']"
                                    role="button"
                                    gaEvent="click_test"
                                    gaCategory="ga_directive_test">
                                    {{ 'Kategori Produk' | translate }}
                                </a>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf="
                        checkAkses('produk', 'status') ||
                        checkAkses('produk_ulasan', 'status') ||
                        checkAkses('produk_kategori', 'status')
                    ">
                    <li class="dropdown" ngbDropdowntoggle>
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <i class="bx bx-box mr-3"></i>
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Produk' | translate }}</span>
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)"
                                    href="javascript: void(0);" [routerLink]="[
                                        '/backoffice/master/produkKategori'
                                    ]" role="button" *ngIf="checkAkses('produk_kategori', 'status')"
                                    gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Kategori Produk' | translate }}
                                </a>
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)"
                                    href="javascript: void(0);" [routerLink]="[
                                        '/backoffice/master/produk'
                                    ]" role="button" *ngIf="checkAkses('produk', 'status')" gaEvent="click_test"
                                    gaCategory="ga_directive_test">
                                    {{ 'Data Produk' | translate }}
                                </a>
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)"
                                    href="javascript: void(0);" [routerLink]="[
                                        '/backoffice/master/ulasan'
                                    ]" role="button" *ngIf="
                                        checkAkses(
                                            'produk_ulasan',
                                            'status'
                                        )
                                    " gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Ulasan Produk' | translate }}
                                </a>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf=" checkAkses('stok_masuk', 'status') || checkAkses('perbarui_stok', 'status')">
                    <li class="dropdown" ngbDropdowntoggle>
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <img src="assets/images/nav_inventory.png" height="20" class="mr-3">
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Inventori' | translate }}</span>
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" *ngIf="
                                        checkAkses('stok_masuk', 'status')
                                    " (click)="onSubMenuClick($event)" href="javascript: void(0);" [routerLink]="[
                                        '/backoffice/inventory/stok-masuk'
                                    ]" role="button" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Stok Masuk' | translate }}
                                </a>
                                <a class="dropdown-item dropdown-toggle arrow-none" *ngIf="
                                        checkAkses('perbarui_stok', 'status')
                                    " (click)="onSubMenuClick($event)" href="javascript: void(0);" [routerLink]="[
                                        '/backoffice/inventory/stok-keluar'
                                    ]" role="button" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Perbarui Stok' | translate }}
                                </a>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf="checkAkses('monitoring_pesanan', 'status') || checkAkses('monitoring_retur_bnt', 'status') || checkAkses('monitoring_retur_distributor', 'status')">
                    <li class="dropdown" ngbDropdowntoggle>
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <div class="d-flex mr-3">
                                <img src="assets/images/nav_cart.png" height="17">
                                <span *ngIf="totalNotifPesan" class="total-notif-pesan">
                                    <span class="badge badge-danger rounded-pill">{{totalNotifPesan}}</span>
                                </span>
                            </div>
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Pesanan' | translate }}</span>
                                    <span *ngIf="totalNotifPesan" class="total-notif-pesanan">
                                        <span class="badge badge-danger rounded-pill">{{totalNotifPesan}}</span>
                                    </span>
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="javascript: void(0);" role="button" [routerLink]="['/backoffice/salesOrder/monitoring']" *ngIf="checkAkses('monitoring_pesanan', 'status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Monitoring Pesanan' | translate }}
                                    <span *ngIf="notif_pesanan">
                                        <span class="badge badge-danger rounded-pill">{{notif_pesanan}}</span>
                                    </span>
                                </a>
                            </div>
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref" href="#" id="topnav-components" role="button" (click)="onSubMenuClick($event)" *ngIf="checkAkses('monitoring_retur_bnt', 'status') || checkAkses('monitoring_retur_distributor', 'status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Monitoring Retur' | translate }}
                                    <span *ngIf="notif_bnt || notif_retur">
                                        <span class="badge badge-danger rounded-pill">{{ notif_bnt + notif_retur }}</span>
                                    </span>
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" style="left: 3px;" ngbDropdownMenu>
                                    <div class="dropdown">
                                        <a class="dropdown-item dropdown-toggle arrow-none align-items-center" (click)="onSubMenuClick($event)" href="javascript: void(0);" [routerLink]="['/backoffice/retur_bnt/BNT']" role="button" *ngIf="checkAkses('monitoring_retur_bnt', 'status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                            <span> BNT </span>
                                            <span>
                                                <span *ngIf="notif_bnt">
                                                    <span
                                                        class="badge badge-danger rounded-pill">{{notif_bnt}}</span>
                                                </span>
                                            </span>
                                        </a>
                                        <a class="dropdown-item dropdown-toggle arrow-none"
                                            (click)="onSubMenuClick($event)" href="javascript: void(0);"
                                            [routerLink]="[
                                                '/backoffice/retur_distributor/Distributor'
                                            ]" role="button"
                                            *ngIf="checkAkses('monitoring_retur_distributor', 'status')"
                                            gaEvent="click_test" gaCategory="ga_directive_test">
                                            Distributor
                                            <span *ngIf="notif_retur">
                                                <span
                                                    class="badge badge-danger rounded-pill">{{notif_retur}}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf="checkAkses('video_pelatihan', 'status') || checkAkses('konten_promosi', 'status')">
                    <li class="dropdown" ngbDropdowntoggle>
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <div class="d-flex mr-3">
                                <img src="assets/images/nav_member.png" height="14">
                                <span *ngIf="totalNotifTraining > 0" class="total-notif-pesan">
                                    <span class="badge badge-danger rounded-pill">{{totalNotifTraining}}</span>
                                </span>
                            </div>
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Pengembangan' | translate }}</span>
                                    <span *ngIf="totalNotifTraining > 0" class="total-notif-pesanan">
                                        <span class="badge badge-danger rounded-pill">{{totalNotifTraining}}</span>
                                    </span>
                                    <i class="fas fa-angle-right font-size-12" [ngClass]="totalNotifTraining ? 'ml-1' : 'ml-3'"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" [routerLink]="['/backoffice/training/video-training']" href="javascript: void(0);" role="button" *ngIf="checkAkses('video_pelatihan', 'status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Video Pelatihan' | translate }}
                                    <span *ngIf="notif_training">
                                        <span class="badge badge-danger rounded-pill">{{notif_training}}</span>
                                    </span>
                                </a>
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" [routerLink]="['/backoffice/training/konten-promosi']" href="javascript: void(0);" role="button" *ngIf="checkAkses('konten_promosi','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Konten Promosi' | translate }}
                                    <span *ngIf="notif_konten_promosi">
                                        <span
                                            class="badge badge-danger rounded-pill">{{notif_konten_promosi}}</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf="checkAkses('penyesuaian_ewallet', 'status') || checkAkses('insentif_bulanan', 'status') || checkAkses('pembayaran_tagihan', 'status')">
                    <li class="dropdown" ngbDropdowntoggle *ngIf="checkAkses('penyesuaian_ewallet', 'status') || checkAkses('insentif_bulanan', 'status') || checkAkses('pembayaran_tagihan', 'status')">
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <img src="assets/images/nav_keuangan.png" height="13" class="mr-3">
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Keuangan' | translate }}</span>
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown" *ngIf="checkAkses('penyesuaian_ewallet', 'status')">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                                    [routerLink]="['/backoffice/keuangan/mutasiEwallet']"
                                    href="javascript: void(0);" id="topnav-components" role="button"
                                    gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Penyesuaian E-Wallet' | translate }}
                                </a>
                            </div>
                            <div class="dropdown" *ngIf="checkAkses('penyesuaian_komisi', 'status')">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                                    [routerLink]="['/backoffice/keuangan/penyesuaian_komisi']"
                                    href="javascript: void(0);" id="topnav-components" role="button"
                                    gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{'Penyesuaian Komisi' | translate }}
                                </a>
                            </div>
                            <div class="dropdown" *ngIf="checkAkses('insentif_bulanan', 'status')">
                                <a class="dropdown-item dropdown-toggle arrow-none"
                                    [routerLink]="['/backoffice/keuangan/insentifBulanan']"
                                    (click)="onSubMenuClick($event)" href="javascript: void(0);"
                                    id="topnav-components" role="button" gaEvent="click_test"
                                    gaCategory="ga_directive_test">
                                    {{ 'Insentif Bulanan' | translate }}
                                </a>
                            </div>
                            <div class="dropdown" *ngIf="checkAkses('pembayaran_tagihan', 'status')">
                                <a class="dropdown-item dropdown-toggle arrow-none"
                                    [routerLink]="['/backoffice/pembelian/tagihan']"
                                    (click)="onSubMenuClick($event)" href="javascript: void(0);"
                                    id="topnav-components" role="button" gaEvent="click_test"
                                    gaCategory="ga_directive_test">
                                    {{ 'Pembayaran Tagihan' | translate }}
                                </a>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf="checkAkses('penyesuaian_ewallet', 'status') || checkAkses('insentif_bulanan', 'status') || checkAkses('pembayaran_tagihan', 'status')">
                    <li class="dropdown" ngbDropdowntoggle *ngIf="checkAkses('penyesuaian_ewallet', 'status') || checkAkses('insentif_bulanan', 'status') || checkAkses('pembayaran_tagihan', 'status')">
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <img src="assets/images/nav_email.png" width="20" class="mr-3">
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Email' | translate }}</span>
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown" *ngIf="checkAkses('penyesuaian_ewallet', 'status')">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                                    [routerLink]="['/backoffice/email/email-notifikasi']"
                                    href="javascript: void(0);" id="topnav-components" role="button"
                                    gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Email Notifikasi' | translate }}
                                </a>
                            </div>
                            <div class="dropdown" *ngIf="checkAkses('penyesuaian_komisi', 'status')">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                                    [routerLink]="['/backoffice/email/email-blast']"
                                    href="javascript: void(0);" id="topnav-components" role="button"
                                    gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{'Email Blast' | translate }}
                                </a>
                            </div>
                        </div>
                    </li>
                </div>
                <div class="dropdown" ngbDropdown *ngIf="
                        checkAkses('laporan_pesanan', 'status') ||
                        checkAkses('laporan_rekap_stok','status') || checkAkses('laporan_kartu_stok','status') ||
                        checkAkses('laporan_insentif_langsung','status') || checkAkses('laporan_insentif_periode','status') ||
                        checkAkses('laporan_pembayaran','status') || checkAkses('laporan_rekap_ewallet','status') || checkAkses('laporan_mutasi_ewallet','status') || checkAkses('laporan_laba_rugi','status') ||
                        checkAkses('laporan_achievement','status') || checkAkses('laporan_perpindahan_agensi','status') || checkAkses('laporan_pohon_jaringan','status')
                    ">
                    <li class="dropdown" ngbDropdowntoggle>
                        <a class="nav-link dropdown-toggle arrow-none text-white d-flex align-items-center" id="topnav-pages" role="button">
                            <img src="assets/images/nav_laporan.png" height="18" class="mr-3">
                            <span class="text-link w-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ 'Laporan' | translate }}</span>
                                    <i class="fas fa-angle-right font-size-12"></i>
                                </div>
                            </span>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown" *ngIf="checkAkses('laporan_pesanan','status')">
                                <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)"href="#" role="button" [routerLink]="['/backoffice/laporan/penjualan']" *ngIf="checkAkses('laporan_pesanan','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Pesanan' | translate }}
                                </a>
                            </div>
                            <div class="dropdown"
                                *ngIf="checkAkses('laporan_rekap_stok','status') || checkAkses('laporan_kartu_stok','status')">
                                <a class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref" href="#"
                                    id="topnav-components" role="button" (click)="onSubMenuClick($event)">
                                    {{ 'Inventori' | translate }}
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" style="left: 3px;">
                                    <a class="dropdown-item dropdown-toggle arrow-none"
                                        (click)="onSubMenuClick($event)" 
                                        *ngIf="checkAkses('laporan_rekap_stok','status')" 
                                        href="#" 
                                        role="button" 
                                        [routerLink]="['/backoffice/laporan/rekap-stok-principle']" 
                                        gaEvent="click_test" 
                                        gaCategory="ga_directive_test">
                                        {{ 'Rekap Penjualan Barang' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" *ngIf="checkAkses('laporan_kartu_stok', 'status')" href="#" role="button" [routerLink]="['/backoffice/laporan/riwayat-kartu-stok']" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Kartu Stok' | translate }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown"
                                *ngIf="checkAkses('laporan_insentif_langsung','status') || checkAkses('laporan_insentif_periode','status')">
                                <a class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref" href="#"
                                    id="topnav-components" role="button" (click)="onSubMenuClick($event)"
                                    gaEvent="click_test" gaCategory="ga_directive_test">
                                    {{ 'Insentif Pengguna' | translate }}
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" style="left: 3px;">
                                    <a class="dropdown-item dropdown-toggle arrow-none"
                                        (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="[
                                            '/backoffice/laporan/insentif'
                                        ]" *ngIf="
                                            checkAkses(
                                                'laporan_insentif_langsung',
                                                'status'
                                            )
                                        " gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Insentif Langsung' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none"
                                        (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="[
                                            '/backoffice/laporan/insentifBulanan'
                                        ]" *ngIf="
                                            checkAkses(
                                                'laporan_insentif_periode',
                                                'status'
                                            )
                                        " gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Insentif Periode' | translate }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown" *ngIf="checkAkses('laporan_pembayaran','status') || checkAkses('laporan_rekap_ewallet','status') || checkAkses('laporan_mutasi_ewallet','status') || checkAkses('laporan_laba_rugi','status')">
                                <a class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref" href="#" id="topnav-components" role="button" (click)="onSubMenuClick($event)">
                                    {{ 'Keuangan' | translate }}
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" style="left: 3px; top: 32px">
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/pembayaran']" *ngIf="checkAkses('laporan_pembayaran','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Pembayaran' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/transaksi-eWallet']" *ngIf="checkAkses('laporan_rekap_ewallet','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Rekap E-Wallet' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/rekap-pajak']" *ngIf="checkAkses('laporan_rekap_pajak','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{'Rekap Pajak' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/laporan-mutasi-eWallet']" *ngIf="checkAkses('laporan_mutasi_ewallet','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Mutasi E-Wallet' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/laporan-laba-rugi']" *ngIf="checkAkses('laporan_laba_rugi','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Laba Rugi Operasional' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none" (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/pengejaran-omset']" *ngIf="checkAkses('laporan_laba_rugi','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Pengejaran Omset' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none"(click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="['/backoffice/laporan/promo-pv']" *ngIf="checkAkses('laporan_promo_pv','status')" gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Promo Point Value' | translate }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown"
                                *ngIf="checkAkses('laporan_achievement','status') || checkAkses('laporan_perpindahan_agensi','status') || checkAkses('laporan_pohon_jaringan','status')">
                                <a class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref" href="#"
                                    id="topnav-components" role="button" (click)="onSubMenuClick($event)">
                                    Member
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" style="left: 3px;">
                                    <a class="dropdown-item dropdown-toggle arrow-none"
                                        (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="[
                                            '/backoffice/laporan/laporan-achievement'
                                        ]" *ngIf="
                                            checkAkses(
                                                'laporan_achievement',
                                                'status'
                                            )
                                        " gaEvent="click_test" gaCategory="ga_directive_test">
                                        Achievement
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none"
                                        (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="[
                                            '/backoffice/laporan/perpindahan-agency'
                                        ]" *ngIf="
                                            checkAkses(
                                                'laporan_perpindahan_agensi',
                                                'status'
                                            )
                                        " gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Perpindahan Agensi' | translate }}
                                    </a>
                                    <a class="dropdown-item dropdown-toggle arrow-none"
                                        (click)="onSubMenuClick($event)" href="#" role="button" [routerLink]="[
                                            '/backoffice/laporan/pohonjaringan'
                                        ]" *ngIf="checkAkses('laporan_pohon_jaringan', 'status')"
                                        gaEvent="click_test" gaCategory="ga_directive_test">
                                        {{ 'Pohon Jaringan' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
                <li class="nav-item" style="top: 90%; position: sticky;">
                    <a class="nav-link arrow-none text-nowrap text-white d-flex" href="javascript: void(0);" (click)="logout()" id="topnav-components" role="button" gaEvent="click_test" gaCategory="ga_directive_test">
                        <img src="assets/images/nav_logout.png" height="18" class="mr-3">
                        <span class="text-link">
                            {{ 'Keluar' | translate }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</div>
<ng-template #modalGPassword let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">{{ 'Ganti Password' | translate }}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group row">
                    <label
                        class="col-md-4 col-form-label col-form-label-sm required">{{ 'Kata Sandi' | translate }}</label>
                    <div class="col-md-8">
                        <input class="form-control form-control-sm" type="password" [(ngModel)]="model.password"
                            name="password" placeholder="Masukkan Password Baru" />
                    </div>
                </div>
                <div class="form-group row">
                    <label
                        class="col-md-4 col-form-label col-form-label-sm required">{{ 'Ulangi Kata Sandi' | translate }}</label>
                    <div class="col-md-8">
                        <input class="form-control form-control-sm" type="password" [(ngModel)]="model.repassword"
                            name="repassword" placeholder="Ulangi Password Baru" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right">
            <button class="btn btn-primary btn-sm" (click)="savepasswords(); modal.dismiss('Cross click')"
                [disabled]="model.password != model.repassword">
                <i class="fa fa-save font-size-14 align-middle mr-2"></i>
                {{ 'Simpan' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #bahasa let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">{{ 'Ganti Bahasa' | translate }}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6 text-center">
                <img src="../../../assets/images/indonesia.png" width="210px" (click)="switchLanguage('IND')">
                <p>IND</p>
            </div>
            <div class="col-md-6 text-center">
                <img src="../../../assets/images/united-kingdom.png" width="210px" (click)="switchLanguage('ENG')">
                <p>ENG</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <div class="float-right">
            <button class="btn btn-primary btn-sm" (click)="savepasswords(); modal.dismiss('Cross click')" [disabled]="model.password != model.repassword">
                <i class="fa fa-save font-size-14 align-middle mr-2"></i>
                {{ 'Simpan' | translate }}
            </button>
        </div> -->
    </div>
</ng-template>