import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import Swal from 'sweetalert2';
import { LandaService } from '../../core/services/landa.service';

@Injectable({
    providedIn: 'root',
})
export class CheckupdateService {
    constructor(private readonly updates: SwUpdate, private landaService: LandaService) {}

    getUpdate() {
        setInterval(() => {
            this.isAvailable();
        }, 10000);
    }

    isAvailable() {
        const getUser = this.landaService.getSession();
        let user = getUser == undefined ? '' : getUser['id']; 
        // this.landaService
        //     .DataGet('/l_notifikasi/counter', {user: user})
        //     .subscribe((res: any) => {
        //         localStorage.setItem('notification', res.data);
        // });
        this.updates.available.subscribe((event) => {
            this.doAppUpdate();
            // this.showAppUpdateAlert();
        });
    }

    showAppUpdateAlert() {
        Swal.fire({
            title: 'Perhatian',
            text: 'Terdapat pembaruan pada sistem BNT, silakan klik Perbarui Sistem',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'Perbarui Sistem',
        }).then((result) => {
            if (result.value) {
                this.doAppUpdate();
            }
        });
    }

    doAppUpdate() {
        this.landaService.logout();
        localStorage.clear();
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}
