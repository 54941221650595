import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AuthenticationService } from './auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { type } from 'os';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LandaService {
    apiURL = environment.apiURL;
    imageURL = environment.imageURL;
    userDetail: any;
    userToken: any;
    httpOptions: any;
    user: any;
    session: any;
    el: any = [];
    location: any = [];
    userPermission: any;

    private messageSource = new BehaviorSubject<String>('');
    currentMessage = this.messageSource.asObservable();

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private router: Router,
        private translate: TranslateService,
    ) {
        this.userDetail = this.authenticationService.getDetailUser();
        this.userToken = this.authenticationService.getToken();
        if (
            this.userDetail !== null &&
            typeof this.userDetail['akses'] !== undefined
        ) {
            this.userPermission = this.userDetail.akses;
        } else {
            this.userPermission = {};
        }

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.userToken,
            }),
            withCredentials: true,
        };
        const langToSet = localStorage.getItem('lang');
        this.translate.addLangs(['IND', 'ENG']);
        this.translate.setDefaultLang(langToSet);
        this.translate.use(langToSet);
    }

    ngOnInit(): void { }

    sendMessage(message) {
        this.messageSource.next(message);
    }

    getSession() {
        const d = localStorage.getItem('user');
        if (d != null) {
            this.session = this.parseJwt(d);
        }
        return this.session;
    }

    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    GetApi(url, params = {}, authorization = '') {
        return this.http.get(url, {
            params,
            withCredentials: true,
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authorization,
            }),
        });
    }

    checkAkses(hakAkses, sub) {
        const res = this.userPermission[hakAkses];
        let status = false;
        if (
            res !== undefined &&
            Object.prototype.hasOwnProperty.call(res, sub) &&
            typeof res[sub] !== undefined
        ) {
            status = res[sub];
        }
        return status;
    }

    getImage(folder: string, image: string) {
        let ret;

        if (image !== '' && image !== null && folder !== '') {
            ret = this.imageURL + folder + '/' + image;
        } else if (image !== '' && image !== null && folder === '') {
            ret = this.imageURL + image;
        } else {
            ret = this.imageURL + 'default.png';
        }
        return ret;
    }

    /**
     * Request GET
     */
    DataGet(path, payloads = {}) {
        return this.http.get(this.apiURL + path, {
            params: payloads,
            withCredentials: true,
        });
    }

    /**
     * Request POST
     */
    DataPost(path, payloads = {}, isHtml: boolean = false) {
        if (isHtml === true) {
            const reqHeader = {
                responseType: 'text' as 'json',
                withCredentials: true,
            };
            return this.http.post(this.apiURL + path, payloads, reqHeader);
        } else {
            const reqHeader = this.httpOptions;
            return this.http.post(this.apiURL + path, payloads, reqHeader);
        }
    }

    /**
     * Request Api
     */
    DataApiGet(path, payloads = {}) {
        return this.http.get(path, {
            params: payloads,
            withCredentials: false
        });
    }
    DataApiPost(path, payloads = {}) {
        return this.http.post(path, payloads, {});
    }
    DataApiPut(path, payloads = {}) {
        return this.http.put(path, payloads, {});
    }
    DataApiDelete(path, payloads = {}) {
        return this.http.delete(path, {
            params: payloads,
        });
    }

    /**
     * Request POST
     */
    CustomDataPost(api, payloads = {}, isCustom = false) {
        if (isCustom == true) {
            const opt = {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    QISCUS_SDK_SECRET: '3e6a67381de77032645dee3b4442e5e2',
                    // QISCUS_SDK_APP_ID : environment.AppId,
                    Authorization: '',
                })
            };
            return this.http.post(api, payloads, opt);
        }
        else {
            return this.http.post(api, payloads);
        }
    }

    /**
     * Sweet alert Sukses
     */
    alertSuccess(title, content, html = false, timer = 5, confirm = true) {
        if (html == false) {
            Swal.fire({
                title,
                text: content,
                icon: 'success',
                timer: timer * 1000,
                showConfirmButton: false,
            });
        } else if (html == true) {
            Swal.fire({
                title,
                html: content,
                icon: 'success',
                timer: timer * 1000,
                showConfirmButton: false,
            });
        }
    }

    /**
     * Sweet alert warning
     */
    alertWarning(title, content, html = false, timer = 3.5) {
        if (html == false) {
            Swal.fire({
                title,
                text: content,
                icon: 'warning',
                timer: timer * 1000,
                showConfirmButton: true,
            });
        } else if (html == true) {
            Swal.fire({
                title,
                html: content,
                icon: 'warning',
                timer: timer * 1000,
                showConfirmButton: true,
            });
        }
    }

    /**
     * Sweet alert info
     */
    alertInfo(title, content, html = false, timer = 3.5) {
        if (html == false) {
            Swal.fire({
                title,
                text: content,
                icon: 'info',
                timer: timer * 1000,
                showConfirmButton: true,
            });
        } else if (html == true) {
            Swal.fire({
                title,
                html: content,
                icon: 'info',
                timer: timer * 1000,
                showConfirmButton: true,
            });

        }
    }

    /**
     * Sweet alert error
     */
    alertError(title, content) {
        let isi = '';
        let pecah = '';
        let translate = '';
        if (Array.isArray(content) === true) {
            pecah = content[0].replace(/(<([^>]+)>)/gi, "");
            translate = this.translate.instant(pecah);
            isi = isi + translate + ' <br>';
            // content[0].forEach((element) => {
            //     pecah = element.replace(/(<([^>]+)>)/gi, "");
            //     translate = this.translate.instant(pecah);
            //     isi = isi + translate + ' <br>';
            // });
        } else {
            isi = String(this.translate.instant(content));
        }
        Swal.fire(title, isi, 'error');
    }

    alertNoConfirm(title, content, html = false, timer = 2){
        if (html == false) {
            Swal.fire({
                title,
                text: content,
                icon: 'info',
                timer: timer * 1000,
                showConfirmButton: false,
            });
        } else if (html == true) {
            Swal.fire({
                title,
                html: content,
                icon: 'info',
                timer: timer * 1000,
                showConfirmButton: false,
            });

        }
    }

    toast(type, title) {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'green',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
        })
        toast.fire({
            icon: type,
            title: title,

        })
    }



    // Untuk menampilkan alert jika akses lokasi diblokir
    locationPermission() {
        navigator.geolocation.getCurrentPosition(
            (success) => {
                return true;
            },
            (error) => {
                // this.alertError(
                //     'Terjadi Kesalahan',
                //     'Akses lokasi ditolak silakan aktifkan akses lokasi pada pengaturan browser terlebih dahulu'
                // );
                return false;
            }
        );
    }

    onMenuClick(e: any) {
        e.preventDefault();
        this.location.push(e.target.href);
        if (this.location.length > 2) {
            this.location.shift();
        }
        if (
            this.location[0] === this.location[1] &&
            this.location[0] !== undefined
        ) {
            window.location.reload();
        }
        if (this.el[0]) {
            this.el[0].remove('bg-info', 'text-white');
            this.el[0].remove('bg-info', 'text-white');
            this.el.shift();
        }
    }

    onSubMenuClick(event: any, status: boolean = false) {
        event.preventDefault();
        this.location.push(event.target.href);

        if (this.location.length > 2) {
            this.location.shift();
        }

        if (this.location[0] === this.location[1]) {
            window.location.reload();
        }

        if (status === true) {
            if (event.target.tagName === 'A') {
                this.el.push([
                    event.target.parentNode.classList,
                    event.target.classList,
                ]);
                if (this.el.length > 1) {
                    if (typeof this.el[0][0] === 'string') {
                        this.el[0].remove('bg-info', 'text-white');
                        this.el[0].remove('bg-info', 'text-white');
                        this.el.shift();
                    } else {
                        this.el[0][0].remove('bg-info', 'text-white');
                        this.el[0][1].remove('bg-info', 'text-white');
                        this.el.shift();
                    }
                }
            } else if (event.target.tagName === 'DIV') {
                this.el.push([
                    event.target.lastChild.classList,
                    event.target.classList,
                ]);
                if (this.el.length > 1) {
                    if (typeof this.el[0][0] === 'string') {
                        this.el[0].remove('bg-info', 'text-white');
                        this.el[0].remove('bg-info', 'text-white');
                        this.el.shift();
                    } else {
                        this.el[0][0].remove('bg-info', 'text-white');
                        this.el[0][1].remove('bg-info', 'text-white');
                        this.el.shift();
                    }
                }
            }
        } else {
            this.el.push(event.target.classList);
            if (this.el.length > 1) {
                if (typeof this.el[0][0] === 'string') {
                    this.el[0].remove('bg-info', 'text-white');
                    this.el[0].remove('bg-info', 'text-white');
                    this.el.shift();
                } else {
                    this.el[0][0].remove('bg-info', 'text-white');
                    this.el[0][1].remove('bg-info', 'text-white');
                    this.el.shift();
                }
            }
        }
    }

    /**
     * Get total notification
     */
    getTotalNotification() {
        return new Promise((resolve, reject) => {
            this.DataGet('/notifikasi/get_total', {}).subscribe((res: any) => {
                if (res.status_code === 200) {
                    resolve(res);
                }
            });
        });
    }

    /**
     * Get random ID dengan menentukan panjang
     */
    randomId(length = 10) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    /**
     * Detect browser dan versi browser
     */
    // getBrowser() {
    //     var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
    //     if(/trident/i.test(M[1])){
    //         tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
    //         return {name:'IE',version:(tem[1]||'')};
    //     }
    //     if(M[1]==='Chrome'){
    //         tem=ua.match(/\bEdg\/(\d+)/)
    //         if(tem!=null)   {return {name:'Edge(Chromium)', version:tem[1]};}
    //         tem=ua.match(/\bOPR\/(\d+)/)
    //         if(tem!=null)   {return {name:'Opera', version:tem[1]};}
    //     }
    //     M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    //     if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    //     return {
    //       name: M[0],
    //       version: M[1]
    //     };
    // }

    getBrowser() {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bEdg\/(\d+)/)
            if (tem != null) { return { name: 'Edge(Chromium)', version: tem[1] }; }
            tem = ua.match(/\bOPR\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resp => {
                resolve({ long: resp.coords.longitude, lat: resp.coords.latitude });
            }, err => {
                reject(err);
            });
        });
    }

    saveLog($params) {
        this.DataGet('/logHistory/save-log', $params).subscribe((res:any) => {});
    }

    logout() {
        this.DataGet('/site/logout', {}).subscribe((res: any) => {
            this.authenticationService
                .logout()
                .then((res: any) => {
                    this.router.navigate(['/account/login']);
                })
                .catch((error) => { });
        });
    }
}
