import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { 
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { PetaComponent } from './peta/peta.component';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { NodataComponent } from './nodata/nodata.component';
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component';
import { ScannerComponent } from './scanner/scanner.component';
import { MultipleUploadComponent } from './multiple-upload/multiple-upload.component';
import { MultipleFotoComponent } from './multiple-foto/multiple-foto.component';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { FilterPeriodeComponent } from './filter-periode/filter-periode.component';
import { SelectPrincipleComponent } from './select/select-principle/select-principle.component';
import { SelectProdukComponent } from './select/select-produk/select-produk.component';
import { SelectMemberComponent } from './select/select-member/select-member.component';
import { InputTanggalComponent } from './input/input-tanggal/input-tanggal.component';
import { InputAngkaComponent } from './input/input-angka/input-angka.component';
import { MonthDatePickerComponent } from './month-date-picker/month-date-picker.component';
import { SelectMobileComponent } from './select/select-mobile/select-mobile.component';
import { MobileStatusOrderComponent } from './ui/mobile-status-order/mobile-status-order.component';
import { InputEmailComponent } from './input/input-email/input-email.component';
import { CropFotoComponent } from './crop-foto/crop-foto.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { KatalogNavbarComponent } from './katalog-navbar/katalog-navbar.component';
import { LoaderKontenComponent } from './ui/loader-konten/loader-konten.component';
import { CoreModule } from '../core/core.module';
// import { appInitializerFactory } from '../app.module';
import { NgbDateCustomParserFormatter } from '../core/date-formatter';
import { HttpConfigInterceptor } from '../core/interceptor/http-config.interceptor';
import { GoogleMapsModule } from '@angular/google-maps';
import { MobileFootbarComponent } from './mobile-footbar/mobile-footbar.component';
import { PetaRegisComponent } from './peta-regis/peta-regis.component';
import { SelectBankComponent } from './select/select-bank/select-bank.component';


@NgModule({
    declarations: [
        PetaComponent,
        PagetitleComponent,
        MobileNavComponent,
        NodataComponent,
        MobileSidebarComponent,
        ScannerComponent,
        MultipleUploadComponent,
        MultipleFotoComponent,
        PasswordStrengthBarComponent,
        FilterPeriodeComponent,
        SelectPrincipleComponent,
        SelectProdukComponent,
        SelectMemberComponent,
        InputTanggalComponent,
        InputAngkaComponent,
        MonthDatePickerComponent,
        SelectMobileComponent,
        MobileStatusOrderComponent,
        InputEmailComponent,
        CropFotoComponent,
        KatalogNavbarComponent,
        LoaderKontenComponent,
        MobileFootbarComponent,
        PetaRegisComponent,
        SelectBankComponent
    ],
    exports: [
        FilterPeriodeComponent,
        PetaComponent,
        PetaRegisComponent,
        PagetitleComponent,
        MobileNavComponent,
        NodataComponent,
        MobileSidebarComponent,
        ScannerComponent,
        MultipleUploadComponent,
        MultipleFotoComponent,
        PasswordStrengthBarComponent,
        SelectPrincipleComponent,
        SelectProdukComponent,
        SelectMemberComponent,
        InputTanggalComponent,
        InputAngkaComponent,
        MonthDatePickerComponent,
        SelectMobileComponent,
        MobileStatusOrderComponent,
        CropFotoComponent,
        LoaderKontenComponent,
        MobileFootbarComponent,
        SelectBankComponent
    ],
    providers: [
        NgxImageCompressService,
        AsyncPipe,
        // MessagingService,
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'id-ID',
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        LeafletModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        Daterangepicker,
        NgxMaskModule,
        NgbModule,
        ImageCropperModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CoreModule,
        GoogleMapsModule
    ],
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(
    translate: TranslateService,
    injector: Injector
) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );
            locationInitialized.then(() => {
                let language;
                const langToSet = localStorage.getItem('lang');
                
                translate.setDefaultLang(langToSet);
                translate.addLangs(['IND', 'ENG']);

                // const browserLang = translate.getBrowserLang();
                // translate.use(browserLang.match(/ENG|IND/) ? browserLang : 'ENG');

                translate.use(langToSet).subscribe(
                    (translated: string) => {
                        language = translated;
                    },
                    (err) => {
                        console.log(err);
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(language);
                    }
                );
            });
        });
}

