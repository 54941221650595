import {
    Component,
    OnInit,
    AfterViewInit,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-horizontal',
    templateUrl: './horizontal.component.html',
    styleUrls: ['./horizontal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {
    constructor(private translate: TranslateService) {
        const langToSet = localStorage.getItem('lang');
        translate.setDefaultLang(langToSet);
        translate.addLangs(['IND', 'ENG']);
    }

    ngOnInit() {
        document.body.setAttribute('data-layout', 'horizontal');
        document.body.setAttribute('data-topbar', 'light');
        document.body.removeAttribute('data-sidebar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-sidebar-small');
    }

    ngAfterViewInit() {}

    /**
     * on settings button clicked from topbar
     */
    onSettingsButtonClicked() {
        document.body.classList.toggle('right-bar-enabled');
    }
}
