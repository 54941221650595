import {
    Component,
    OnInit,
    AfterViewInit,
    Inject,
    ViewEncapsulation,
    HostListener,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../core/services/auth.service';
import { LandaService } from '../../core/services/landa.service';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';
import { CheckupdateService } from '../../core/services/checkupdate.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-horizontaltopbar',
    templateUrl: './horizontaltopbar.component.html',
    styleUrls: ['./horizontaltopbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
    element;
    configData;
    user;
    listPerusahaan;
    model: {
        id;
        kode;
        nama;
        password;
        repassword;
    };
    modelGbr: {
        logo_menu;
    };
    gambar: any = {};
    listPengumuman: any = [];
    dataModalPengumuman: {
        judul;
        isi;
        tanggal;
        penulis;
    };
    listGambar: any = [];
    isRouter: string;
    //   el: any;
    //   location: any;
    showDashboard: boolean;
    isLoginPrinciple = false;
    acc_resign: boolean;
    totalData: number;
    totalNotif: any;
    notif_pesanan: any;
    notif_bnt: any;
    notif_retur: any;
    totalNotifPesan: any;
    totalpesan_retur: any;
    totalpesan_bnt: any;
    akses_monitoring: any;
    akses_retur_bnt: any;
    akses_retur_distributor: any;
    notif_training: any;
    akses_video_pelatihan: any;
    totalNotifTraining: any;
    akses_konten_promosi: any;
    notif_konten_promosi: any;
    logo_aplikasi: any = {};

    // tslint:disable-next-line: max-line-length
    constructor(
        @Inject(DOCUMENT) private document: any, 
        private checkUpdate: CheckupdateService,
        private router: Router,
        private authService: AuthenticationService,
        private modalService: NgbModal,
        private landaService: LandaService,
        private translate: TranslateService
    ) {
        translate.addLangs(['IND', 'ENG']);
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activateMenu();
            }
        });
    }

    ngOnInit(): void {
        this.element = document.documentElement;
        this.isRouter = this.router.url;
        this.configData = {
            suppressScrollX: true,
            wheelSpeed: 0.3,
        };
        this.listPerusahaan = [];
        this.user = this.authService.getDetailUser();
        
        if (this.user.type_akun === 'Admin Principle') {
            this.isLoginPrinciple = true;
        } else {
            this.isLoginPrinciple = false;
        }
        this.showDashboard = false;
        this.acc_resign = false;
        this.checkResign(this.user);
        this.getTotalData();
        this.getIcon();
        const langToSet = localStorage.getItem('lang');
        this.translate.setDefaultLang(langToSet);
        this.translate.currentLang = this.translate.defaultLang;
    }

    

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (
            document.body.scrollTop > 200 ||
            document.documentElement.scrollTop > 200
        ) {
            document.getElementById('page-topbar').classList.add('topbarputih');
            document.getElementById('topnav').classList.add('topnavbiru');
            // document.getElementById('page-content').classList.add('pt-4');
        } else {
            document
                .getElementById('page-topbar')
                .classList.remove('topbarputih');
            document.getElementById('topnav').classList.remove('topnavbiru');
            // document.getElementById('page-content').classList.remove('pt-4');
        }
    }

    getIcon() {
        this.landaService
            .DataGet('/m_setting_aplikasi/get_foto', {})
            .subscribe((res: any) => {
                this.logo_aplikasi.logo_sidebar = res.data.logo_biru;
                this.logo_aplikasi.ikon_sidebar = res.data.ikon_biru;
            });
    }

    checkAkses(hakAkses, sub) {
        return this.landaService.checkAkses(hakAkses, sub);
    }

    gantiPassword(modal) {
        this.modalService.open(modal, { size: 'md' });
        this.model = {
            id: '',
            kode: '',
            nama: '',
            password: '',
            repassword: '',
        };
    }

    savepasswords() {
        const data = this.model.password;
        Swal.fire({
            title: 'Apakah kamu yakin ?',
            text: 'Mengubah password kamu',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'Ya, ubah password !',
        }).then((result) => {
            if (result.value) {
                this.landaService
                    .DataPost('/m_pengguna/updatePassword', { data })
                    .subscribe((res: any) => {
                        if (res.status_code === 200) {
                            this.landaService.alertSuccess(
                                'Berhasil',
                                'Data Password Baru telah disimpan !'
                            );
                        } else {
                            this.landaService.alertError(
                                'Mohon Maaf',
                                res.errors
                            );
                        }
                    });
            }
        });
    }

    /**
     * Action logout
     */
    logout() {
        this.landaService.DataGet('/site/logout', {}).subscribe((res: any) => {
            this.authService
                .logout()
                .then((res: any) => {
                    this.router.navigate(['/account/login']);
                })
                .catch((error) => {});
        });
    }

    onSubMenuClick(e) {
        this.landaService.onSubMenuClick(e);
    }

    onMenuClick(e) {
        this.landaService.onMenuClick(e);
    }

    ngAfterViewInit() {
        this.activateMenu();
    }

    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
        const els = document.getElementsByClassName(className);
        while (els[0]) {
            els[0].classList.remove(className);
        }
    }

    /**
     * Togglemenu bar
     */
    toggleMenubar() {
        const element = document.getElementById('topnav-menu-content');
        element.classList.toggle('show');
    }

    /**
     * Activates the menu
     */
    private activateMenu() {

        this.getIcon();
        const resetParent = (el: any) => {
            const parent = el.parentElement;
            if (parent) {
                parent.classList.remove('active');
                const parent2 = parent.parentElement;
                this._removeAllClass('mm-active');
                this._removeAllClass('mm-show');
                if (parent2) {
                    parent2.classList.remove('active');
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove('active');
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            parent4.classList.remove('active');
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove('active');
                            }
                        }
                    }
                }
            }
        };

        // activate menu item based on location
        const links = document.getElementsByClassName('side-nav-link-ref');
        let matchingMenuItem = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // reset menu
            resetParent(links[i]);
        }
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (location.pathname === links[i]['pathname']) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            const parent = matchingMenuItem.parentElement;
            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add('active');
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.add('active');
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add('active');
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            parent4.classList.add('active');
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.add('active');
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * on settings button clicked from topbar
     */
    onSettingsButtonClicked() {
        document.body.classList.toggle('right-bar-enabled');
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle('fullscreen-enable');
        if (
            !document.fullscreenElement &&
            !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement
        ) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    /**
     * Topbar light
     */
    topbarLight() {
        document.body.setAttribute('data-topbar', 'light');
        document.body.removeAttribute('data-layout-size');
    }

    /**
     * Set boxed width
     */
    boxedWidth() {
        document.body.setAttribute('data-layout-size', 'boxed');
        document.body.setAttribute('data-topbar', 'dark');
    }

    /**
     * Colored header
     */
    coloredHeader() {
        document.body.setAttribute('data-topbar', 'colored');
        document.body.removeAttribute('data-layout-size');
    }

    /**
     * Change the layout onclick
     * @param layout Change the layout
     */
    changeLayout(layout: string) {
        window.location.reload();
    }

    checkResign(user) {
        const link = window.location.href;
        const hostname = window.location.hostname;
        const url = link.split('/');
        let result;
        if (url[2] === 'localhost:4200') {
            result = link.split('http://' + hostname + ':4200/');
        } else {
            result = link.split('https://' + hostname + '/');
        }
        this.landaService
            .DataPost('/resign/check-resign', {
                id: user.id,
                status_id: user.m_user_status_id,
            })
            .subscribe((res: any) => {
                if (
                    'Admin BNT' === user.type_akun &&
                    'acc-resign' === res.data.status &&
                    result[1] === 'backoffice/pemberitahuan/acc-resign'
                ) {
                    this.acc_resign = true;
                } else {
                    this.acc_resign = false;
                }
            });
    }

    getTotalData() {
        this.landaService .DataGet('/notifikasi/get_total', {}).subscribe((res: any) => {
            if (res.status_code === 200) {
                this.totalData = res.data.total_all;
                this.notif_pesanan = res.data.total.monitoring_pesan;
                this.notif_bnt = res.data.total.monitoring_BNT;
                this.notif_retur = res.data.total.monitoring_Distributor;
                this.notif_training = res.data.total.video_training;
                this.notif_konten_promosi = res.data.total.konten_promosi;
                
                this.akses_monitoring = this.user.akses.monitoring_pesanan.status;
                this.akses_retur_bnt = this.user.akses.monitoring_retur_bnt.status;
                this.akses_retur_distributor = this.user.akses.monitoring_retur_distributor.status;
                this.akses_retur_distributor = this.user.akses.monitoring_retur_distributor.status;
                this.akses_video_pelatihan = this.user.akses.video_pelatihan.status;
                this.akses_konten_promosi = this.user.akses.konten_promosi.status;

                if (this.akses_monitoring === true && this.akses_retur_bnt === true && this.akses_retur_distributor === true){   
                    this.totalNotifPesan  =  this.notif_pesanan + this.notif_retur + this.notif_bnt;
                }
                else if (this.akses_monitoring === true && this.akses_retur_bnt === true) {
                    this.totalNotifPesan = this.notif_pesanan + this.notif_bnt;
                }
                else if (this.akses_monitoring === true && this.akses_retur_distributor === true ) {
                    this.totalNotifPesan = this.notif_pesanan + this.notif_retur;
                }
                else if (this.akses_monitoring === true) {
                    this.totalNotifPesan = this.notif_pesanan
                }
                else if(this.akses_retur_bnt === true){
                    this.totalNotifPesan = this.notif_bnt;
                }
                else if(this.akses_retur_distributor === true){
                    this.totalNotifPesan = this.notif_retur;
                }
                
                if(this.akses_video_pelatihan == true && this.akses_konten_promosi == true){
                    this.totalNotifTraining = this.notif_training + this.notif_konten_promosi;
                }

            }
        });
    }

    checkupdate(){
        this.checkUpdate.showAppUpdateAlert();
    }

    modalBahasa(modal) {
        this.modalService.open(modal, { size: 'md', backdrop: 'static' });
    }

    switchLanguage(val) {
        localStorage.setItem("lang", val);
        location.reload();
    }
}
