declare var QiscusSDKCore: any;
import { Component, Injectable } from '@angular/core';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, ChildActivationStart, ChildActivationEnd, ActivationStart, ActivationEnd, ActivatedRoute, Routes } from '@angular/router';
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDateStruct,
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { CheckupdateService } from './core/services/checkupdate.service';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';
    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }
    toModel(date: NgbDateStruct | null): string | null {
        return date
            ? date.day +
                  this.DELIMITER +
                  date.month +
                  this.DELIMITER +
                  date.year
            : null;
    }
}
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';
    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }
    format(date: NgbDateStruct | null): string {
        return date
            ? date.day +
                  this.DELIMITER +
                  date.month +
                  this.DELIMITER +
                  date.year
            : '';
    }
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent {
    title = 'push-notification';
    message: any;
    effectiveType: String;
    isOnline: boolean = false;
    showAlertNetwork: boolean =false;
    loadingRouteConfig: boolean = true;
    
    constructor(
        private checkUpdate: CheckupdateService,
        public translate: TranslateService,
        private router: Router,
        private modalService: NgbModal,
    ) {
        translate.addLangs(['IND', 'ENG']);
        translate.setDefaultLang('IND');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/ENG|IND/) ? browserLang : 'ENG');
    }
       
    ngOnInit() {
        this.loadingRouteConfig = true;
        this.showAlertNetwork = false;
        this.createOnline$().subscribe(
            isOnline => this.isOnline = isOnline
        );
        this.router.events.subscribe(event => {
            switch (true) {
                case event instanceof ActivationStart:
                case event instanceof RouteConfigLoadStart:
                case event instanceof ChildActivationStart:
                case event instanceof NavigationStart: {
                    this.loadingRouteConfig = true;
                    break;
                }
                
                case event instanceof ActivationEnd:
                case event instanceof RouteConfigLoadEnd:
                case event instanceof ChildActivationEnd:
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loadingRouteConfig = false;
                    break;
                }
                default: {
                    break;
                }
            }
            const conn = (navigator as any).connection;
            if (conn) {
                if (conn.saveData) {
                    console.log('here', conn);
                }
                const connectionlist = ["slow-2g", "2g", "3g", "4g"];
                this.effectiveType = conn.effectiveType;
                if(this.effectiveType !== "4g"){
                    this.showAlertNetwork = true;
                }
                else{
                    this.showAlertNetwork = false;
                }
            }
            this.modalService.dismissAll();
        });
        this.checkUpdate.getUpdate();
    }
    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
            sub.next(navigator.onLine);
            sub.complete();
        }));
    }
}
