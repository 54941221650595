import { Action } from '@ngrx/store';

export enum CartActionTypes {
    SET_TOTAL = 'SET_TOTAL',
}

export class SetTotal implements Action {
    readonly type = CartActionTypes.SET_TOTAL
    constructor(public payload: any){}
}

export type CartActions = SetTotal
