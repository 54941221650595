import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoRightClickDirective } from './directive/no-right-click.directive';
import { TerjemahanPipe } from './pipe/terjemahan.pipe';

@NgModule({
  declarations: [NoRightClickDirective, TerjemahanPipe],
  imports: [
    CommonModule
  ],
  exports: [NoRightClickDirective, TerjemahanPipe]
})

export class CoreModule { }
