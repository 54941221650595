// #server app live
let api = 'https://api.bnt-global.com';
let img = 'https://api.bnt-global.com/assets/img/';
let pdf = 'https://api.bnt-global.com/assets/pdf/';
let chat = 'https://bnt-global.com/chat-window/';

// #server app test
if (window.location.hostname === "test.bnt-global.com") {
    api = 'https://apitest.bnt-global.com';
    img = 'https://apitest.bnt-global.com/assets/img/';
    pdf = 'https://apitest.bnt-global.com/assets/pdf/';
    chat = 'https://test.bnt-global.com/chat-window/';
}

// #server app venturo
if (window.location.hostname === "inventory.venturo.pro") {
    api = 'https://apiinventory.venturo.pro';
    img = 'https://apiinventory.venturo.pro/assets/img/';
    pdf = 'https://apiinventory.venturo.pro/assets/pdf/';
    chat = 'https://inventory.venturo.pro/chat-window/';
}

let appVersion = "1.13.9.21";
let GaAnalythic = "G-KF9DZWC88V";
let unpkg = 'https://unpkg.com/qiscus-sdk-core@2.11.1/dist/qiscus-sdk-core.min.js';
let multiUrl = 'https://multichannel.qiscus.com/api/v2/qiscus/initiate_chat';
let appId = 'nmjul-phrghptefvid1pt';
let channelId = '126652';
let qiscus = {};
let nonce = '';
let roomId = {};

export const environment = {
    production: true,
    mapboxKey:
        'pk.eyJ1IjoiYmF5dWZhamFyaXlhbnRvIiwiYSI6ImNrdHhxM21jdjAwOXkydG5nYXE5YnhpbzYifQ.Q1n0l_-iJgZAXOtP4cF69g',
    googleAnalythic: GaAnalythic,
    apiURL: api,
    pdfUrl: pdf,
    imageURL: img,
    unpkg: unpkg,
    AppId: appId,
    qiscus: qiscus,
    multiUrl: multiUrl,
    channelId: channelId,
    nonce: nonce,
    roomId: roomId,
    chatUrl: chat,
    appVersion: appVersion
};
