import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss']
})
export class ChatWindowComponent implements OnInit, OnDestroy, AfterViewInit {
  Qismo: any;
  user;

  constructor(private authenticationService: AuthenticationService,

  ) {

  }

  ngOnInit() {
    this.user = this.authenticationService.getDetailUser();

  }

  ngAfterViewInit() {
    const style = document.createElement('style');
    style.textContent = `
    body {
			height: 100vh;
			width: 100vw;
			margin: 0;
		}

		.qcw-trigger-btn {
			display: none !important;
		}

		#qcw-iframe {
			width: 100% !important;
      height: calc(100vh + 80px)!important;
      max-width: 480px !important;
      right: unset !important;
      bottom: unset !important;
      left: unset !important;
      top: -80px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      position: relative !important;
		}
`;
document.head.appendChild(style);
    const qiscusls = localStorage.getItem("qismo-widget");

    if (!qiscusls) {
      localStorage.setItem("qismo-widget", JSON.stringify({
        unique_id: this.user.email,
        display_name: this.user.nama_lengkap,
      }));
    }

    const params = {
      options: {
        channel_id: environment.channelId,  // Channel ID
      }
    };

    var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.id = 'qismo-js';
    s.src = 'https://s3-ap-southeast-1.amazonaws.com/qiscus-sdk/public/qismo/qismo-v3.js'; s.async = true;
    s.onload = s.onreadystatechange = function () { new Qismo(environment.AppId, params); }
    t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);

  }

  ngOnDestroy() {
    document.getElementById('qismo-js') && document.getElementById('qismo-js').remove();
    document.getElementById('qismo-widget') && document.getElementById('qismo-widget').remove();
    document.querySelector('#qcw-iframe') && document.querySelector('#qcw-iframe').remove();
    document.querySelector('#qcw-login-form-iframe') && document.querySelector('#qcw-login-form-iframe').remove();
    document.querySelector('#qcw-welcome-iframe') && document.querySelector('#qcw-welcome-iframe').remove();
    // window.Qismo = undefined;
  }

}
