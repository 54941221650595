import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { LandaService } from '../services/landa.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    dataAccResign: any;
    test: any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private landaService: LandaService
    ) {}

    has(obj, key) {
        return obj ? Object.prototype.hasOwnProperty.call(obj, key) : false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.test = this.landaService.DataGet('/resign/index-acc', {}).subscribe((res:any) => {
            this.dataAccResign = res.data.list;
            let totalItems = res.data.totalItems;

            if (totalItems > 0) {
                this.router.navigate(['/backoffice/pemberitahuan/acc-resign']);
                return false;
            }
        });

        const userRole = this.authenticationService.getRole();
        if (userRole !== null && userRole === 'Member') {
            this.router.navigate(['/dashboard']);
            return false;
        } else if (userRole === null) {
            this.router.navigate(['/account/login']);
            return false;
        } 
        


        return true;
    }


}
