import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LAYOUT_VERTICAL, LAYOUT_HORIZONTAL, MOBILE } from './layouts.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
    // layout related config
    layoutType: string;
    message: any;
    constructor(private modalService: NgbModal) {}

    ngOnInit() {
        this.modalService.dismissAll();
    }

    ngAfterViewInit() {}

    /**
     * Check if the vertical layout is requested
     */
    isVerticalLayoutRequested() {
        return this.layoutType === LAYOUT_VERTICAL;
    }

    /**
     * Check if the horizontal layout is requested
     */
    isHorizontalLayoutRequested() {
        return this.layoutType === LAYOUT_HORIZONTAL;
    }

    checkLayout() {
        if (window.location.href.includes('backoffice')) {
            return 'horizontal';
        } else {
            return 'mobile';
        }
    }
}
