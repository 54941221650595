import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { LandaService } from '../services/landa.service';

@Injectable({ providedIn: 'root' })
export class AuthMobileGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private landaService: LandaService
    ) {}

    has(obj, key) {
        return obj ? Object.prototype.hasOwnProperty.call(obj, key) : false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const role = this.authenticationService.getRole();
        const user: any = this.authenticationService.getDetailUser();

        if (role !== null) {
            if (role === 'Admin BNT') {
                if (user.m_roles_id === 7) {
                    return true;
                } else {
                    this.router.navigate(['/backoffice/home']);
                    return false;
                }
            } else {
                return true;
            }
        } else {
            // untuk render data halaman awal pada frontend
            this.router.navigate(['/intro']);   
            return false;
        }
    }
}
