// CORE
import {
    AsyncPipe,
    LOCATION_INITIALIZED,
    registerLocaleData,
} from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import localeId from '@angular/common/locales/id';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// LIBRARY
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IConfig } from 'ngx-mask';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { SignaturePadModule } from '@ng-plus/signature-pad';

// APP
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateCustomParserFormatter } from './core/date-formatter';
import { HttpConfigInterceptor } from './core/interceptor/http-config.interceptor';
import { cartReducer } from './core/state/reducers/cart.reducer';
import { monthYearReducer } from './core/state/reducers/filtermonth.reducer';
import { SharedModule } from './shared/shared.module';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

registerLocaleData(localeId);
export const options: Partial<IConfig> = {
    thousandSeparator: '.',
};
@NgModule({
    declarations: [AppComponent],
    imports: [
        StoreModule.forRoot({ cart: cartReducer, monthYear: monthYearReducer }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgProgressModule,
        NgProgressHttpModule,
        NgxOrgChartModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        AngularFireMessagingModule,
        // AngularFireModule.initializeApp(environment.firebaseConfig),
        ToastrModule.forRoot({}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        SharedModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalythic),
        NgxGoogleAnalyticsRouterModule,
        SignaturePadModule

    ],
    providers: [
        AsyncPipe,
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'id-ID',
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        // {
        //     provide: ErrorHandler,
        //     useValue: Sentry.createErrorHandler({
        //         showDialog: false,
        //     }),
        // },
        // {
        //     provide: Sentry.TraceService,
        //     deps: [Router],
        // },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(
    translate: TranslateService,
    injector: Injector
) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );
            locationInitialized.then(() => {
                let language;
                const langToSet = localStorage.getItem('lang');
                if (langToSet == "null") {
                    localStorage.setItem('lang', "IND");
                } else {
                    localStorage.setItem('lang', langToSet);
                }

                translate.setDefaultLang(langToSet);
                translate.addLangs(['IND', 'ENG']);

                // const browserLang = translate.getBrowserLang();
                // translate.use(browserLang.match(/ENG|IND/) ? browserLang : 'ENG');

                translate.use(langToSet).subscribe(
                    (translated: string) => {
                        language = langToSet;
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(langToSet);
                    }
                );
            });
        });
}
