import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import Swal from 'sweetalert2';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        let token: string = this.authenticationService.getToken();

        if (token) {
            request = request.clone({
                headers: request.headers.set(
                    'Authorization',
                    'Bearer ' + token
                ),
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                headers: request.headers.set(
                    'Content-Type',
                    'application/json'
                ),
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json'),
        });


        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.status_code === 401) {
                    Swal.fire({
                        title: 'Ooops',
                        text: 'Sistem kami mendeteksi perubahan pengaturan keamanan, silakan login ulang terlebih dahulu',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#34c38f',
                        cancelButtonColor: '#f46a6a',
                        confirmButtonText: 'Login Ulang',
                    }).then((result) => {
                        if (result.value) {
                            token = '';
                            this.authenticationService.logout();

                            window.location.reload();

                        }
                    });
                    return throwError(error);
                }
            })
        );
    }
}
