import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart} from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';
import { LoginFrontendGuard } from './core/guards/login-frontend.guard';
import { AuthMobileGuard } from './core/guards/auth-mobile.guard';
import { LayoutComponent } from './layouts/layout.component';
import { ChatComponent } from './frontend/chat/chat.component';
import { ChatWindowComponent } from './frontend/chat/chat-window/chat-window.component';

const routes: Routes = [
    {
        path: 'account',
        canActivate: [LoginGuard],
        loadChildren: () =>
            import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'backoffice',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./backend/backend.module').then((m) => m.BackendModule),
    },
    {
        path: '',
        component: LayoutComponent,
        // canActivate: [AuthGuard],
        loadChildren: () =>
            import('./frontend/frontend.module').then((m) => m.FrontendModule),
    },
    {
        path: 'chat-window',
        component: ChatWindowComponent,
        canActivate: [AuthMobileGuard],
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            useHash: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    loadingRouteConfig: boolean = true;
}
