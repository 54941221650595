import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    decodedToken: { [key: string]: string };
    expiryTime: any;

    constructor() {}

    /**
     * Decode token
     */
    decodeToken() {
        const token = localStorage.getItem('user');
        if (token) {
            this.decodedToken = jwt_decode(token);
        }
    }

    /**
     * get Token
     */
    getToken() {
        const token = localStorage.getItem('user');
        if (token) {
            return token;
        }

        return '';
    }

    /**
     * Ambil user dari local Storage
     */
    getDetailUser() {
        const token = localStorage.getItem('user');
        if (token !== null) {
            return jwt_decode(token);
        }

        return null;
    }

    /**
     * Ambil type user
     */
    getRole() {
        const user = this.getDetailUser();
        if (user !== null) {
            return user['type_akun'];
        } else {
            return null;
        }
    }

    /**
     * Set user ke local Storage
     */
    setDetailUser(payload: any) {
        return new Promise((resolve, reject) => {
            localStorage.setItem('user', payload);
            resolve(true);
        });
    }

    /**
     * Hapus user dari local Storage
     */
    removeUser() {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('user');
            resolve(true);
        });
    }

    /**
     * Logout
     */
    logout() {
        return new Promise((resolve, reject) => {
            this.removeUser();
            resolve(true);
        });
    }

    /**
     * Ambil tanggal expired token
     */
    getExpiryTime() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.exp : null;
    }

    /**
     * Cek apakah token expired
     */
    isTokenExpired(): boolean {
        this.expiryTime = this.getExpiryTime();
        if (this.expiryTime) {
            return 1000 * this.expiryTime - new Date().getTime() < 5000;
        } else {
            return false;
        }
    }
}
