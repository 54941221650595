import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

  // Sentry.init({
  //   dsn: "https://a8cb55db4c231a955a5381cdde2cc1ff@o4505883092975616.ingest.sentry.io/4506776898174976",
  //   integrations: [
  //     // Registers and configures the Tracing integration,
  //     // which automatically instruments your application to monitor its
  //     // performance, including custom Angular routing instrumentation
  //     Sentry.browserTracingIntegration()
  //   ],
  
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  
  //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", "http://localhost/bnt-v2/api"],
  // });

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
            navigator.serviceWorker.register('/combine-sw.js');
        }
    })
    .catch((err) => console.log(err));
